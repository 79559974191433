import { useQuery } from "@apollo/client";
import { graphql } from "lib/gql";
import { unique } from "utils/index";

const availabilitiesQuery = graphql(`
    query pelangganCentreSearchUseAvailabilities(
        $request: [ServiceAvailabilityRequest!]!
        $quotationsRequest: [QuotationRequest!]!
    ) {
        serviceAvailabilities(request: $request) {
            isAvailable
            tenantId
            serviceId
        }
        quotations(request: $quotationsRequest) {
            quotationFor
            timeFullyAccounted
        }
    }
`);

export const useAvailabilities = (
    requests: {
        tenantId: string;
        serviceId: string;
        startDt: string;
        endDt: string;
    }[],
): { availableCentres: string[]; loading: boolean } => {
    const { data, loading } = useQuery(availabilitiesQuery, {
        fetchPolicy: "network-only",
        variables: {
            request: requests,
            quotationsRequest: requests.map((r) => ({
                tenantId: r.tenantId,
                setFor: r.serviceId,
                start: r.startDt,
                end: r.endDt,
            })),
        },
        skip: requests.length === 0,
    });
    const quotations = data?.quotations;
    const availabilities = data?.serviceAvailabilities;
    if (!quotations || !availabilities) {
        return { availableCentres: [], loading };
    }

    const hasQuotation = (sid: string): boolean =>
        quotations.some((q) => q.timeFullyAccounted && q.quotationFor === sid);
    const availableCentres = availabilities
        .filter((sa) => sa.isAvailable && hasQuotation(sa.serviceId))
        .map((a) => a.tenantId);

    return { availableCentres: unique(availableCentres), loading };
};
