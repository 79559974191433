import { css } from "@emotion/react";
import { useEffect, useMemo, useState } from "react";

import { Form, message, Select, Typography } from "antd";
import { EnvironmentOutlined, AimOutlined } from "@ant-design/icons";
import { colourCss } from "config/theme";
import { useTranslation } from "react-i18next";
import { getGroupedLocationsByState } from "utils";

const styles = {
    labelFont: css`
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
    `,
    locationSubheading: css`
        font-size: 16px;
        font-weight: 700;
        color: #16056b;
        margin-top: 10px;
    `,
    locationIcon: css`
        margin-right: 12px;
        font-size: 16px;
    `,
    venueLabelContainer: css`
        white-space: pre-wrap;
        margin-top: 5px;
        line-height: 19px;
        display: inline-flex;
    `,
};

type Centre = {
    name: string;
    uid: string;
    services: string[];
};

type LocationSelectorProps = {
    centres: Centre[];
    selectedCategory?: {
        uid: string;
        name: string;
        locations: string[];
    };
    initialLocationValue?: string;
    locationOptions: { name: string }[];
};

const LocationSelector = ({
    centres,
    selectedCategory,
    initialLocationValue,
    locationOptions,
}: LocationSelectorProps): JSX.Element => {
    const categoryCentres = useMemo(
        () =>
            (selectedCategory?.uid
                ? centres.filter((c) =>
                      c.services.includes(selectedCategory.uid),
                  )
                : centres.slice()
            ).sort((a, b) => a.name.localeCompare(b.name)),
        [selectedCategory, centres],
    );

    const [selectedLocation, setSelectedLocation] =
        useState(initialLocationValue);
    const [showDivider, setShowDivider] = useState(
        isCentre(categoryCentres, initialLocationValue),
    );
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        setSelectedLocation((selectedLocation) =>
            isCentre(categoryCentres, selectedLocation)
                ? selectedLocation
                : undefined,
        );
    }, [categoryCentres, setSelectedLocation]); // Should run only on first render and category changes

    const { t, ready } = useTranslation("common");

    if (!ready) return <div />;

    const categoryLocations =
        selectedCategory?.locations && selectedCategory.locations.length > 0
            ? locationOptions.filter((l) =>
                  selectedCategory.locations.includes(l.name),
              )
            : locationOptions;
    const locationsByState = getGroupedLocationsByState(categoryLocations);

    const isSelectedCentre = isCentre(categoryCentres, selectedLocation);
    const showVenues = isSearching || isSelectedCentre;

    const groupedCentres = [
        {
            title: t("venues", { defaultValue: "Venues" }),
            locations: isSearching
                ? categoryCentres
                : categoryCentres.filter((c) => c.uid === selectedLocation),
        },
    ];

    const onSelect = (value: string): void => {
        if (value === "current_location") {
            navigator.geolocation.getCurrentPosition(
                // will get position again when form is submitted
                // performing this now to get permission
                () => {},
                (err) => {
                    // Error codes taken from https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
                    if (err.code === 1) {
                        message.error(
                            t("get_current_position_permission_denied", {
                                defaultValue:
                                    "Unable to retrieve your location. Please grant the Courtsite website permission to use your location.",
                            }),
                        );
                    } else {
                        message.error(
                            t("get_current_position_fail", {
                                defaultValue:
                                    "Unable to retrieve your location",
                            }),
                        );
                    }
                },
            );
        }
        setSelectedLocation(value);
        setIsSearching(false);
        setShowDivider(isCentre(categoryCentres, value));
    };

    const onSearch = (value: string): void => {
        const hasAvailableLocations = locationsByState.some((g) =>
            g.locations.some((l) => isMatch(value, l.name)),
        );

        setIsSearching(!!value);
        setShowDivider(hasAvailableLocations);
    };

    return (
        <Form.Item
            name="location"
            rules={[
                {
                    required: true,
                    message: t("location_select_error", {
                        defaultValue: "Please enter or select a location",
                    }),
                },
            ]}
            className="ant-form-select"
            label={
                <div css={[colourCss.textPrimary, styles.labelFont]}>
                    {t("location", { defaultValue: "Location" })}
                </div>
            }
        >
            <Select
                bordered={false}
                listHeight={233}
                placeholder={
                    <span css={colourCss.textPrimary}>
                        {t("location_select_placeholder", {
                            defaultValue: "Enter or select a location",
                        })}
                    </span>
                }
                showArrow={false}
                optionLabelProp="label"
                allowClear
                showSearch
                filterOption={(input, option) => isMatch(input, option?.title)}
                onSearch={onSearch}
                onSelect={onSelect}
                onBlur={() => setIsSearching(false)}
                onClear={() => setSelectedLocation(undefined)}
                notFoundContent={t("location_not_found", {
                    defaultValue: "No location found!",
                })}
            >
                {!isSearching && (
                    <Select.Option
                        value="current_location"
                        label={t("use_my_location", {
                            defaultValue: "Use my current location",
                        })}
                        style={{ padding: "10px 12px" }}
                    >
                        <AimOutlined
                            css={[
                                colourCss.secondaryBright,
                                styles.locationIcon,
                            ]}
                        />
                        {t("use_my_location", {
                            defaultValue: "Use my current location",
                        })}
                    </Select.Option>
                )}
                {locationsByState.map((group) => (
                    <Select.OptGroup
                        key={group.state}
                        label={
                            <div css={styles.locationSubheading}>
                                {group.state}
                            </div>
                        }
                    >
                        {group.locations.map((value) => (
                            <Select.Option
                                value={value.name}
                                key={value.name}
                                title={value.name}
                                style={{ padding: "10px 12px" }}
                                label={value.name}
                            >
                                <EnvironmentOutlined
                                    css={[
                                        colourCss.secondaryBright,
                                        styles.locationIcon,
                                    ]}
                                />
                                {value.name}
                            </Select.Option>
                        ))}
                    </Select.OptGroup>
                ))}
                {showVenues &&
                    groupedCentres.map((group) => (
                        <Select.OptGroup
                            key={group.title}
                            label={
                                <div>
                                    {showDivider && (
                                        <div
                                            css={{
                                                borderTop: "1px solid #E2E2E2",
                                                padding: "8px 0",
                                            }}
                                        />
                                    )}
                                    <span css={styles.locationSubheading}>
                                        {group.title}
                                    </span>
                                </div>
                            }
                        >
                            {group.locations.map((c) => (
                                <Select.Option
                                    value={c.uid}
                                    key={c.name}
                                    title={c.name}
                                    style={{ padding: "10px 12px" }}
                                    label={
                                        <div css={styles.venueLabelContainer}>
                                            <Typography.Paragraph
                                                ellipsis={{
                                                    rows: 2,
                                                    expandable: false,
                                                }}
                                            >
                                                {c.name}
                                            </Typography.Paragraph>
                                        </div>
                                    }
                                >
                                    <div css={styles.venueLabelContainer}>
                                        <EnvironmentOutlined
                                            css={[
                                                colourCss.secondaryBright,
                                                styles.locationIcon,
                                            ]}
                                        />
                                        <Typography.Paragraph
                                            ellipsis={{
                                                rows: 2,
                                                expandable: false,
                                            }}
                                            style={{ marginBottom: 0 }}
                                        >
                                            {c.name}
                                        </Typography.Paragraph>
                                    </div>
                                </Select.Option>
                            ))}
                        </Select.OptGroup>
                    ))}
            </Select>
        </Form.Item>
    );
};

const isMatch = (input: string, option?: string): boolean => {
    if (!option) {
        return false;
    }
    return option.toLowerCase().startsWith(input.toLowerCase());
};

const isCentre = (centres: Centre[], value?: string): boolean => {
    if (!value) {
        return false;
    }
    return centres.some((g) => g.uid === value);
};

export default LocationSelector;
