import { css } from "@emotion/react";
import { Row, Col, Button, Select, Form, message } from "antd";
import { SearchOutlined, CloseCircleFilled } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { useQuery } from "@apollo/client";

import dayjs from "lib/dayjs";
import DatePicker, { DatePickerProps } from "components/DatePicker";
import TimePicker from "./TimePicker";
import { colourCss } from "config/theme";
import { useAvailabilities } from "./hooks";
import { useTranslation } from "react-i18next";
import LocationSelector from "./LocationSelector";
import { graphql } from "lib/gql";

const styles = {
    searchContainer: css`
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        background: #fff;
        padding-left: 0px !important;
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            height: 48px;
            transition: none;
        }
        .ant-select-clear {
            top: 35%;
        }
    `,
    column: css`
        height: 100px;
        border-right: 1px solid #e2e2e2;
    `,
    closeIcon: css`
        font-size: 12px;
        transform: translate(-10px, 4px);
        &:hover {
            color: #16056b;
        }
    `,
    datePickerRow: css`
        color: transparent;
        &:hover {
            color: rgba(0, 0, 0, 0.25);
        }
        .ant-form-item-label > label.ant-form-item-required:before {
            content: unset;
        }
    `,
    datePicker: css`
        height: 48px !important;
        align-items: start;
    `,
    form: css`
        .ant-form-item-label > label {
            margin: 22px 20px 0;
            flex-flow: row-reverse;
        }
        .ant-picker-input > input::placeholder {
            color: #16056b;
        }
        .ant-form-item-control {
            margin-top: -12px;
            margin-left: 8px;
        }
        .ant-form-item-has-error
            .ant-select:not(.ant-select-disabled):not(
                .ant-select-customize-input
            )
            .ant-select-selector {
            border: none;
        }
        .ant-form-select .ant-form-item-explain.ant-form-item-explain-error {
            position: absolute;
            bottom: 0;
            left: 10px;
        }
        .ant-form-date-time .ant-form-item-explain.ant-form-item-explain-error {
            position: absolute;
            top: 24px;
            left: 10px;
        }
    `,
    formLabelFont: css`
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
    `,
};

const searchBarFormQuery = graphql(`
    query pelangganCentresSearchBarForm {
        courtsitePartners {
            uid
            name
            services
            location {
                latitude
                longitude
            }
        }
    }
`);
type Category = { uid: string; name: string; locations: string[] };
type Location = { name: string; lat: number; lng: number };
// TODO: SearchValues and FormValues should be the same thing
type SearchValues = {
    categoryId: string;
    location: Location;
    date?: dayjs.Dayjs;
    time?: dayjs.Dayjs;
    duration?: number;
};
type FormValues = {
    category?: string;
    location?: string;
    date?: dayjs.Dayjs;
    time?: dayjs.Dayjs;
    duration?: number;
};
type SearchBarFormProps = {
    initialValues?: {
        // TODO: initialValues should match FormValues
        categoryId: string;
        query: string;
        date?: string;
        time?: string;
        duration?: string;
    };
    categories: Category[];
    locationOptions: {
        name: string;
        latitude: number;
        longitude: number;
        city?: string | null;
    }[];
    isResultsLoading?: boolean;
    onSubmit: (values: SearchValues) => void;
};
export const SearchBarForm = ({
    initialValues,
    categories,
    locationOptions,
    isResultsLoading = false,
    onSubmit,
}: SearchBarFormProps): JSX.Element => {
    const { t, ready } = useTranslation("common");
    const [form] = useForm<FormValues>();

    // centres from /search/[query] page is the availabe centres from courtsiteSearch, not the entire list of it.
    // We need the entire list here
    // TODO: add search by partial name to backend
    const { data } = useQuery(searchBarFormQuery);
    const centres = data?.courtsitePartners ?? [];

    const initialDate = initialValues?.date
        ? dayjs(initialValues.date).startOf("day")
        : undefined;
    const initialFormValues: FormValues = {
        category: initialValues?.categoryId,
        location: initialValues?.query,
        date: initialDate,
        time:
            initialValues?.time && initialDate
                ? initialDate.add(parseFloat(initialValues.time), "h")
                : undefined,
        duration: initialValues?.duration
            ? parseFloat(initialValues.duration)
            : undefined,
    };

    if (!ready) return <div />;

    const clearField = (field: string): void => {
        const fields = {};
        fields[field] = undefined;
        form.setFieldsValue(fields);
        form.validateFields();
    };
    const datePickerProps: DatePickerProps = {
        placeholder: t("date_select_placeholder", "Any day"),
        suffixIcon: null,
        bordered: false,
        allowClear: false,
        format: "DD MMM YYYY, ddd",
        popupStyle: {
            paddingTop: 16,
        },
        disabledDate: (date) => date.isBefore(dayjs(), "day"),
    };

    const handleFinish = async (values: FormValues): Promise<void> => {
        const category = values.category;
        const locationName = values.location;
        if (!category || !locationName) {
            form.validateFields();
            return;
        }

        let location: (typeof locationOptions)[0] | undefined;
        if (locationName === "current_location") {
            try {
                const { coords } = await getPosition();
                const { latitude, longitude } = coords;
                location = { name: locationName, latitude, longitude };
            } catch (err) {
                // Error codes taken from https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError
                if (err.code === 1) {
                    message.error(
                        t(
                            "get_current_position_permission_denied",
                            "Unable to retrieve your location. Please grant the Courtsite website permission to use your location.",
                        ),
                    );
                } else {
                    message.error(
                        t(
                            "get_current_position_fail",
                            "Unable to retrieve your location",
                        ),
                    );
                }
                return;
            }
        } else {
            const venueOptions = centres.map((c) => ({
                name: c.uid,
                latitude: c.location.latitude,
                longitude: c.location.longitude,
            }));
            const locationAndVenueOptions =
                locationOptions.concat(venueOptions);
            location = locationAndVenueOptions.find(
                (l) => l.name === locationName,
            );
        }
        // something went very wrong here, since the possible values are from the same array
        if (!location) throw Error("could not find matching location");

        onSubmit({
            categoryId: category,
            location: {
                name: location.name,
                lat: location.latitude,
                lng: location.longitude,
            },
            date: values.date,
            time: values.time,
            duration: values.duration,
        });
    };

    const handleFormValuesChange = (
        changedValues: Partial<FormValues>,
        allValues: FormValues,
    ): void => {
        if (changedValues.category) {
            const category = categories.find(
                (c) => c.uid === changedValues.category,
            );
            if (!category) {
                throw new Error("invalid category selected");
            }

            const centre = centres.filter((c) => c.uid === allValues.location);

            const hasNoLocation =
                allValues.location &&
                category.locations.length > 0 &&
                !category.locations.includes(allValues.location);

            const hasNoVenues =
                allValues.location &&
                !centre.some((c) => c.services.includes(category.uid));

            const hasNoLocationOrVenue =
                centre.length === 0 ? hasNoLocation : hasNoVenues;

            if (hasNoLocationOrVenue) {
                form.setFieldsValue({ ...allValues, location: undefined });
            }
        }
    };

    categories.sort((a, b) => a.name.localeCompare(b.name));

    return (
        <Form
            form={form}
            onFinish={handleFinish}
            initialValues={initialFormValues}
            onValuesChange={handleFormValuesChange}
            css={styles.form}
            labelCol={{ span: 24 }}
        >
            <Row gutter={[24, 0]}>
                <Col span={20} css={styles.searchContainer}>
                    <Row>
                        <Col span={5} css={styles.column}>
                            <Form.Item
                                name="category"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            "category_select_error",
                                            "Please select a category.",
                                        ),
                                    },
                                ]}
                                className="ant-form-select"
                                label={
                                    <div
                                        css={[
                                            colourCss.textPrimary,
                                            styles.formLabelFont,
                                        ]}
                                    >
                                        {t("category", "Category")}
                                    </div>
                                }
                            >
                                <Select
                                    bordered={false}
                                    listHeight={233}
                                    placeholder={
                                        <span css={[colourCss.textPrimary]}>
                                            {t(
                                                "category_select_placeholder",
                                                "Select a category",
                                            )}
                                        </span>
                                    }
                                    showArrow={false}
                                    optionLabelProp="label"
                                    allowClear
                                >
                                    {categories.map((c) => (
                                        <Select.Option
                                            style={{ padding: "10px 12px" }}
                                            value={c.uid}
                                            key={c.uid}
                                            label={
                                                <span
                                                    css={colourCss.textPrimary}
                                                >
                                                    {c.name}
                                                </span>
                                            }
                                        >
                                            {c.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8} css={styles.column}>
                            <Form.Item shouldUpdate noStyle>
                                {({ getFieldValue }) => {
                                    const categoryId =
                                        getFieldValue("category");
                                    const selectedCategory = categories.find(
                                        (c) => c.uid === categoryId,
                                    );
                                    return (
                                        <LocationSelector
                                            centres={centres}
                                            locationOptions={locationOptions}
                                            initialLocationValue={
                                                initialValues?.query
                                            }
                                            selectedCategory={selectedCategory}
                                        />
                                    );
                                }}
                            </Form.Item>
                        </Col>
                        <Col span={5} css={styles.column}>
                            <Form.Item shouldUpdate noStyle>
                                {({ getFieldValue }) => {
                                    const isDateSelected =
                                        getFieldValue("date");
                                    const isTimeSelected =
                                        getFieldValue("time");
                                    return (
                                        <Row
                                            css={styles.datePickerRow}
                                            align="middle"
                                        >
                                            <Col span={22}>
                                                <Form.Item
                                                    name="date"
                                                    label={
                                                        <div
                                                            css={[
                                                                colourCss.textPrimary,
                                                                styles.formLabelFont,
                                                            ]}
                                                        >
                                                            {t("date", "Date")}
                                                        </div>
                                                    }
                                                    className="ant-form-date-time"
                                                    rules={[
                                                        {
                                                            required:
                                                                isTimeSelected,
                                                            message: t(
                                                                "date_select_error",
                                                                "Please select a date.",
                                                            ),
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker
                                                        css={styles.datePicker}
                                                        {...datePickerProps}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            {isDateSelected && (
                                                <Col span={2}>
                                                    <CloseCircleFilled
                                                        css={styles.closeIcon}
                                                        onClick={() =>
                                                            clearField("date")
                                                        }
                                                    />
                                                </Col>
                                            )}
                                        </Row>
                                    );
                                }}
                            </Form.Item>
                        </Col>
                        <Col span={3} css={styles.column}>
                            <TimeSelector clearField={clearField} />
                        </Col>
                        <Col span={3} css={{ height: 100 }}>
                            <DurationFormItem />
                        </Col>
                    </Row>
                </Col>
                <Col span={4}>
                    <Form.Item shouldUpdate noStyle>
                        {({ getFieldsValue }) => {
                            const formValues = getFieldsValue([
                                "category",
                                "location",
                            ]);
                            return (
                                <Button
                                    type="primary"
                                    block
                                    size="large"
                                    style={{
                                        height: "100px",
                                        boxShadow:
                                            "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                    }}
                                    htmlType="submit"
                                    loading={isResultsLoading}
                                    disabled={
                                        !formValues.category ||
                                        !formValues.location
                                    }
                                >
                                    <span>
                                        <div>
                                            <SearchOutlined className="text-[35px]" />
                                        </div>
                                        <div>{t("search", "Search")}</div>
                                    </span>
                                </Button>
                            );
                        }}
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

const durations = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6];

const DurationFormItem = (): JSX.Element => {
    const { t } = useTranslation("common");
    return (
        <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
                const isDateSelected = getFieldValue("date");
                return (
                    <Form.Item
                        name="duration"
                        label={
                            <span
                                css={[
                                    colourCss.textPrimary,
                                    styles.formLabelFont,
                                ]}
                            >
                                {t("duration", "Duration")}
                            </span>
                        }
                        rules={[
                            {
                                required: isDateSelected,
                                message: t(
                                    "duration_select_error",
                                    "Please select a duration.",
                                ),
                            },
                        ]}
                    >
                        <Select
                            bordered={false}
                            placeholder={
                                <span css={colourCss.textPrimary}>
                                    {t(
                                        "duration_select_placeholder",
                                        "Any duration",
                                    )}
                                </span>
                            }
                            showArrow={false}
                            optionLabelProp="label"
                            allowClear
                        >
                            {durations.map((value) => (
                                <Select.Option
                                    value={value}
                                    key={value}
                                    style={{ padding: "10px 12px" }}
                                    label={
                                        <span css={[colourCss.textPrimary]}>
                                            {t("hour", "{{count}} Hour(s)", {
                                                count: value,
                                            })}
                                        </span>
                                    }
                                >
                                    {t("hour", "{{count}} Hour(s)", {
                                        count: value,
                                    })}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                );
            }}
        </Form.Item>
    );
};

const TimeSelector = ({
    clearField,
}: {
    clearField: (field: string) => void;
}): JSX.Element => {
    const { t, ready } = useTranslation("common");
    if (!ready) return <div></div>;

    const options: dayjs.Dayjs[] = [];
    const startOfDay = dayjs().startOf("day");

    const optionsPivot = 7;
    for (let i = optionsPivot; i < 24; i++) {
        options.push(startOfDay.add(i, "hour"));
        options.push(startOfDay.add(i, "hour").add(30, "minutes"));
    }
    for (let i = 0; i < optionsPivot; i++) {
        options.push(startOfDay.add(i, "hour"));
        options.push(startOfDay.add(i, "hour").add(30, "minutes"));
    }

    const filteredTimeOptions = options.filter(
        (o) => o.unix() >= dayjs().subtract(20, "minutes").unix(), //Added 20 minutes buffer to allow booking for past timings
    );

    return (
        <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
                const date = getFieldValue("date");
                const time = getFieldValue("time");
                const validTimeOptions =
                    date && dayjs(date).isToday()
                        ? filteredTimeOptions
                        : options;
                const isValidTimeSelected =
                    dayjs(date).isToday() &&
                    time?.unix() < dayjs().subtract(20, "minutes").unix();
                return (
                    <Row css={styles.datePickerRow} align="middle">
                        <Col xs={22}>
                            <Form.Item
                                name="time"
                                className="ant-form-date-time"
                                rules={[
                                    {
                                        required: date,
                                        message: t(
                                            "time_select_error",
                                            "Please select a time.",
                                        ),
                                    },
                                ]}
                                label={
                                    <span
                                        css={[
                                            colourCss.textPrimary,
                                            styles.formLabelFont,
                                        ]}
                                    >
                                        {t("time", "Time")}
                                    </span>
                                }
                                valuePropName="time"
                            >
                                <TimePicker
                                    time={
                                        isValidTimeSelected ? undefined : time
                                    }
                                    options={validTimeOptions}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={2}>
                            {time && (
                                <CloseCircleFilled
                                    css={styles.closeIcon}
                                    onClick={() => clearField("time")}
                                />
                            )}
                        </Col>
                    </Row>
                );
            }}
        </Form.Item>
    );
};

const getPosition = (options?: PositionOptions): Promise<GeolocationPosition> =>
    new Promise<GeolocationPosition>((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject, options),
    );

export { useAvailabilities };
