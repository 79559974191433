import dayjs, { Dayjs } from "dayjs";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
import generatePicker, {
    PickerProps,
} from "antd/lib/date-picker/generatePicker";
import "antd/lib/date-picker/style/index";

import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";

dayjs.extend(weekday);
dayjs.extend(localeData);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

export type DatePickerProps = PickerProps<Dayjs>;

export default DatePicker;
