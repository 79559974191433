import { css } from "@emotion/react";

import { useState, useEffect } from "react";
import dayjs from "dayjs";

import isToday from "dayjs/plugin/isToday";
import { useTranslation } from "react-i18next";
dayjs.extend(isToday);

const styles = {
    container: css`
        position: relative;
        min-width: 85px;
    `,
    formField: css`
        max-width: 100%;
        cursor: pointer;
        border-radius: 5px;
        padding-top: 4px;
        padding-bottom: 25px;
        content: unset;
    `,
    dropdown: css`
        position: absolute;
        background-color: white;
        opacity: 0;
        z-index: 50;
        max-height: 0px;
        width: 105%;
        overflow-y: scroll;
        padding: 4px 8px;
        box-shadow: 1px 1px 5px grey;
        border-radius: 5px;
        text-align: center;
        transition:
            max-height 0.5s cubic-bezier(0.16, 1, 0.3, 1),
            opacity 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    `,
    dropdownOpened: css`
        opacity: 1;
        max-height: 250px;
    `,
};

type TimePickerProps = {
    time?: dayjs.Dayjs;
    options: dayjs.Dayjs[];
    onChange?: (v: dayjs.Dayjs) => void;
};

const TimePicker = ({
    time,
    options,
    onChange,
}: TimePickerProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const close = (): void => setIsOpen(false);
        if (isOpen) {
            window.addEventListener("click", close);
        }
        return () => window.removeEventListener("click", close);
    }, [isOpen, setIsOpen]);
    const { t, ready } = useTranslation("common");
    if (!ready) return <div></div>;

    const handleClick = (): void => setIsOpen(!isOpen);
    const handleSelect = (e, v): void => {
        e.stopPropagation();
        setIsOpen(false);
        onChange && onChange(v);
    };

    return (
        <div css={styles.container} onClick={handleClick}>
            <div css={[styles.formField]}>
                <span css={{ marginLeft: 12 }}>
                    {time?.format("hh:mm A") ?? (
                        <span>
                            {t("time_select_placeholder", {
                                defaultValue: "Any time",
                            })}
                        </span>
                    )}
                </span>
            </div>
            <div css={[styles.dropdown, isOpen && styles.dropdownOpened]}>
                {options.map((o: dayjs.Dayjs) => (
                    <div
                        key={o.format("HH:mm")}
                        style={{ padding: 4, cursor: "pointer" }}
                        onClick={(e) => handleSelect(e, o)}
                    >
                        {o.format("hh:mm A")}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TimePicker;
